.cup {
    position: relative;
    width: 95px;
    height: 160px;
    border: 2px solid #000;
    margin: 30px;

    border-radius: 15px 15px 30px 30px;
    overflow: hidden;
}

.liquid {
    position: absolute;
    bottom: 0;
    left: -2px;
    width: 100%;
    height: 0;
    background-color: #ffdada;
    opacity: 1;
}
