/* Footer styles */
.footer-page {
    position: relative;
    width: 100%;              
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-rectangle {
    width: 100%;                
    height: 10rem;            
    padding: 20px;
    background-color: #FFF3F9;   
    display: flex;
    flex-direction: column;      
    justify-content: space-between;
    position: relative;
    border-top: 1px solid black;  
  }
  
  .footer-content {
    display: flex;
    justify-content: center;     
    align-items: center;          
    flex-grow: 1;
  }
  
  .footer-text {
    font-size: 18px;               /* Same font size as the navbar buttons */
    color: #1A1A1A;                  /* Same color as the navbar text */
    margin: 0;
    text-align: center;            /* Center the text inside the rectangle */
  }
  
  /* Icon container positioned in the bottom-right corner */
  .footer-icons {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  
  .footer-icon {
    display: flex;
    justify-content: center;       
    align-items: center;          
    margin-left: 10px;
    width: 50px;                   
    height: 50px;                  
    /* background-color: #F8F8F8;    */
    border-radius: 8px;          
    border: 1px solid #1A1A1A;    
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .footer-icon:hover {
    background-color: #f0f0f0;  /* Light background on hover */
    color: #333;  /* Darker text on hover */  
  }
  

 

  .footer-icon svg {
    width: 24px;                 
    height: 24px;            
    color: #1A1A1A;          
    stroke: #1A1A1A; 
    stroke-width: 1px; 
  }


  
  /* Optional: Make sure the footer looks good on smaller screens */
  @media (max-width: 768px) {
    .footer-rectangle {
      height: auto;                /* Allow it to adjust height on smaller screens */
      flex-direction: column;      /* Stack items vertically */
      justify-content: flex-start; /* Move the icons to the top */
      align-items: center;
    }
  
    .footer-icons {
      position: static;           /* Reset position for mobile */
      margin-top: 10px;
      justify-content: center;
    }
  }
  