header.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #FFF3F9;
    color: black;
    z-index: 1000; /* Ensure navbar is on top of other content */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  */
    display: flex;
    justify-content: flex-end;
    height: 7vh; 
    /* padding: 0 20px; */

    border-bottom: 1px solid black;
   /* border: 2px solid rgba(255, 192, 203, 0.6) ; */
  }
  
  .navbar.hidden {
    transform: translateY(-100%); /* Hide navbar by moving it up */
  }
  
  /* Navigation menu styling */
  .navbar ul {
    list-style-type: none;
    margin: 0;
    margin-right: 1vw;
    padding: 0;
    display: flex;
    height: 100%; 
  }
  
  .navbar ul li {
    height: 100%; /* Ensure li takes up the full height of the navbar */
    margin: 0;
    padding: 0;
  }
  
  .navbar ul li button {
    background: none;
    border:none;
    /* border: 2px solid rgba(255, 192, 203, 0.6);  */
    /* color: black; */
    font-size: 16px;
    color:black;
    cursor: pointer;
    padding: 0 20px; 
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1; 
  }
 

  .navbar ul li button:hover {
    color:  #FFB1B1;
    border-bottom: 1px solid black; 
}