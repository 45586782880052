@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');


.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
  }
  

.home {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100%;
}

.title {
    font-family: "League Spartan", sans-serif;
    font-size: 8.63vh !important;
    color: #1A1A1A;  
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }


.below{
    margin-top: -20px;
    font-size: 2.4vh;
    font-family: 'Space Mono', monospace;
    color: white;
    background: #DE6FA1;
    align-items: center;
    align-self: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}


.icons{
    display: flex; 
    margin-left: -40px;
}

.iconsImg {
    margin: 0 10px;
    text-decoration: none;

}


/*MOBILE*/
@media (max-width: 767px) {
    .home {
      height: 100vh;
      text-align: center;
    margin-left: 20px;
    }

    .title-container {
        flex-direction: column; 
      }

      .title{
        font-size: 7.75vh !important;
      }

      .about-right {
        display: none;
      }
   
      .icons{
       margin-top: 1vh;
    }
    
  

  }