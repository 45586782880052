/* Full-page experience section */
.experience-page {
  overflow: hidden; /* Hide overflow for smooth scrolling */
}

/* Experience page container */
.experience-page-container {
  max-width: 80%;
  justify-content: space-between;
  align-items: flex-start;
}


.projects-page .container {
  margin-left: 10vw;
}


/* Slider container */
.experience-slider {
  display: flex;
  flex-direction: column;
  height: 80vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: center;

  /*Hide scroll bar*/
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow-x: hidden; /* Hide horizontal scroll */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.experience-slider::-webkit-scrollbar {
  display: none;
}

.experience-main-container {
  margin-top: 30vh;
}

/* Individual slide */
.experience-slide {
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-bottom: #dedddd solid;
  padding-bottom: 5rem;
}

/* Experience card container */
.experience-card {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  width: 100%; 
  max-width: 100%; 
  min-width: 80vw; 
  box-sizing: border-box;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 2vh;
}

/* Left side of the card */
.experience-left {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; 
  flex: 1;
  text-align: left;
  margin-right: 5vw;

  z-index: 1; 
}

/* Right side of the card */
.experience-right {
  flex: 1;
}

/* Title and Location styling */
.experience-title {
  font-size: 3vh;
  display: block;
  margin-bottom: 10px;
}

.experience-location {
  font-size: 2vh;
  color: gray;
}

/* Summary and Role styling */
.experience-role {
  font-size: 2.5vh;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.experience-summary {
  font-size: 2vh;
  margin-bottom: 10px;
}


.card-text {
  text-align: left;
  max-width: 80%;
}

.card-text p {
  margin-bottom: 1rem;
}

/* Scroll indicator */
.scroll-indicator {
  position: absolute;
  bottom: 20px;
  transform: translateX(-50%);
  font-size: 24px;
  color: #333;
  cursor: pointer;
  animation: bounce 1.5s infinite;
}

/* Bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Flashlight effect */
.flashlight {
  position: fixed;
  pointer-events: none;
  background: radial-gradient(circle, rgba(255, 192, 203, 0.6) 0%, rgba(255, 192, 203, 0.2) 70%, rgba(255, 192, 203, 0.1) 90%);
  width: 500px;
  height: 500px;
  border-radius: 50%;
  filter: blur(30px);
  mix-blend-mode: multiply;
  transition: background 0.3s ease;
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .experience-page {
    padding: 0;
    align-items: center;
  }

  h1 {
    font-size: 7vh;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .experience-slider {
    width: 100%;
    max-height: 80%;
    padding: 0;
    margin-top: 6vh;
  }


  /* Stack content vertically on mobile */
  .experience-slide {
    padding: 0;
    width: 100%;
    margin-bottom: 50px;
    padding-bottom: 60px;
    flex-direction: column; 
    align-items: center; 
  }

   /* Stack content vertically on mobile */
  .experience-card {
    flex-direction: column;
    width: 90%;
    padding: 10px;
    text-align: center;
  }


  /* Remove sticky position on mobile */
  .experience-left {
    position: static; 
    margin-right: 0; 
    text-align: center; 
    margin-bottom: 1rem; 
    width: 100%; 
  }

  .experience-right {
    width: 100%;
    text-align: center;
  }

  .projects-page .container {
    margin-left: 0vw;
  }
  
}
