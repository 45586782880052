@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

/* Full-page about section */
.page-2 {
  /* overflow: hidden;  */
  position: relative; 
  padding: 0;
  margin: 0; 
}

.about-page-container{
    max-height: 55vh;
   

    justify-content: space-between;

}
/* Container to ensure consistent width and centering */
.container.about-page-container {
  max-width: 80%;
  margin: 0 auto; /* Center horizontally */
  padding: 0; /* Remove padding */
}

/* Main container for the about page */
.about-main-container {
  width: 100%;
  margin-top: 10vh; /* Align with other sections */
  padding: 0; /* Remove padding */
}

/* About content container */
.about-content {
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items at the center */
  width: 100%;
  justify-content: space-between; 
}

/* Left side of the content */
.about-left {
  flex: 1;
  max-width: 45%;
  text-align: left;
}

/* Intro text styling */
.intro-text {
  font-size: 2.0vh;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.intro-text-experience {
    font-size: 2.2vh;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }

/* Right side of the content */
.techstack-section {
  flex: 1;
  text-align: right;
  margin-top: -12vh;
}

/* Fade-in animations */
.item {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 2s ease, transform 2s ease;
}

.item.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Mobile adjustments */
/* Center align on mobile */
@media (max-width: 767px) {
    .about-main-container {
      display: flex;
      flex-direction: column; 
      align-items: center;

      width: 100%;
    }
  
    .about-left {
      display: block; 
      /* text-align: center;  */
      margin-bottom: 1rem; 
      max-width: 100%;
    }
  
    .techstack-section {
      display: none; 
    }
    .intro-text {
        font-size: 16px;
      }
      .intro-text-experience  {
        font-size: 14px; 
      }
  } 