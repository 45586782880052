@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');


.timeline-container {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 1vw;
  }
  
  .timeline {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  
  .timeline-step {
    position: relative;
    flex: 1;
    padding: .5vh;
    text-decoration: none;
    font-size: 2vh;
    font-family: "League Spartan", sans-serif;

    color: #333;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .timeline-step:hover {
    background-color: #f4f4f4;
    transform: scale(1.05);
  }
  

/*   
  .arrow {
    width: 2vh;
    height: 20px;
    display: inline-block;
    background: transparent;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transform: rotate(225deg);
    margin: 0 20px;
  } */
  
  .timeline-content {
    margin-top: 6vh;
    text-align: left;
  }
  
  .content-item {
    margin-bottom: 6vh;
  }
  

  
  .timeline-step:hover .tooltip {
    display: block;
  }
  
 

  /* // download buttons */
  .download-buttons {
    bottom: 20px;  /* Distance from the bottom of the viewport */
    display: flex;  /* Flexbox to align buttons vertically */
    flex-direction: column;  /* Stack the buttons vertically */
    align-items: center;  /* Center buttons horizontally */
    padding: 0 20px;  /* Optional: Adjusts the padding on the left and right */
  }
  
  .download-btn {
    margin: 10px 0;  /* Space between buttons */
    padding: 12px 24px;  /* Button size */
    font-size: 16px;
    border: 1px solid black;  /* Black border */
    background-color: transparent;  /* No background color */
    color: black;  /* Text color */
    border-radius: 5px;  /* Rounded corners */
    transition: all 0.3s ease;
    cursor: pointer;
    width: 200px;  /* Fixed width for both buttons */
    text-align: center;  /* Ensure text is centered inside the button */
  }
  
  .download-btn:hover {
    background-color: #f0f0f0;  /* Light background on hover */
    color: #333;  /* Darker text on hover */
  }
  
  .download-btn:focus {
    outline: none;  /* Remove focus outline */
  }
  
  
  
  