
@import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2&family=Calligraffitti&display=swap');


*{
    margin:0;
    padding:0;
    
    box-sizing: border-box;
    
}

html, body {
  height: 100%;            /* Ensure the page takes up the full viewport height */
  margin: 0;               /* Remove default margin */
  padding: 0;              /* Remove any padding */
  width: 100%;             /* Full width for the page */
}


section{
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    flex-direction: column;
    /* background-color: #FFF3F9;  */
    background-color: 	#F8F8F8;
    overflow: hidden; 
    /* ensures content from other sections doesn't show in viewport-fit: ; */
    /* overflow: hidden; */
}

/* Footer-specific styles (doesn't participate in snap scrolling) */
footer {
  position: relative;
  height: auto;
  /* padding: 20px; */
  background-color: #f8f9fa;
  /* margin-top: 40px;  */
  /* Footer will scroll normally */
}
.main-container::-webkit-scrollbar {
    width: 0;
    display: none;
    height: 0;
}

.main-container{
    scroll-snap-type: y mandatory;
    overflow-y:scroll;
    height: 100vh;

    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    scrollbar-width: none;      /* For Firefox */

}




.mango-mode{
    background-color: #ffdcb4;
}



:root {
    --background-color: #FFF3F9;
    --text-color: #333;
    --accent-color: #ff4081;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  button {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
/*DARK THEME*/
  body.dark-theme {
    background-color: #F8F8F8;
    color: #FFB1B1;
  }

 
  body.dark-theme section{
    background-color: 	#F8F8F8

  }

  

/* GLOBAL FONTS */

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');

 h1{
    font-family: 'Space Mono',bold;
    font-size: 4.63vh !important;
    margin-right: 40px;
}

h2{
    font-family: 'Space Mono',bold;
    /* font-size: 23px; */
    margin-right: 40px;
}

h3{
    font-family: 'Space Mono',bold;
    /* font-size: 20px; */
    margin-right: 40px;
}
h4, .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem;
    }
  }

 li{
     text-decoration: none;
     list-style: none;
     padding: 1.5px;
 }





@keyframes bounce {
    50% {
        transform: scale(0.9);
    }
}






.slider {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    height: 100%;


}

.slide {
    flex: 0 0 100%;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.5s ease-in-out;
}
#slide-content-1 { transform: translateX(0); }




.experience-btn{

    margin-top:20px;
}


/*TODO FIX CARD so it's not the image size is the same size as the card*/
.project-card{
    width:155px;
   text-align: center;
}
h6{
    font-size: 10px;
}

.project-left{
    width:600px;
}


