@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');


.stage{
    width:110px;
    height: 105px;
}

.ball-img {
    width: 75%;
    height: 75%;
    
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
    /* margin-left: 13px; */
    object-fit: contain;
}

.ball-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: blue;
    border: #000 solid; */
    margin: 10px;
}

.ball-text {
    margin-top: -15px;
    text-align: center;
    font-family:
    /* font-family: "Space Mono", monospace; 
    font-weight: ; */
}

.ball{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* border: 1.4px solid #de6fa17a;  */
    /* border-color: saddlebrown; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    /*background: black;*/

    /* background: black; */
    margin: 0;
  




}



/*highlight on the ball*/
.ball:after {
      /* width: 100%;
      height: 100%; */
      /* content: ""; */
      /* top: 3%;
      left: 10%; */
      /* border-radius: 50%; */
      /* background: radial-gradient(circle at 50% 50%,  rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
      */
      /* transform: translateX(-60px) translateY(-30px) skewX(-20deg); */
}



/* .ball-shadow{
    background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    z-index: -1;
} */

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
margin-top: -20px;
    height: 80%;
    /* Add other styles for the container */
}


.language-text{
    font-size: 20px;
    /* font-weight: 700; */
    color: #000;
    /* text-transform: uppercase; */
    /* margin-bottom: 10px; */
    /* display: block; */
    margin-top: 10px;
    display: inline-block;
   margin-right: 25px;



   /* gap: 0.6em; */

   /* border-radius: 20px; */
   /* border: 1px solid black; */
   /* padding: 1em 1.8em; */
   font-family: "League Spartan", sans-serif;
   font-weight: 500;
    /* border-bottom: 1px solid #000; */
    width: fit-content;
    padding: .3em 1rem;
    text-align: center;

    cursor: pointer;
    gap: 0.4rem;
    /* font-weight: bold; */
    border-radius: 10px;
    /* text-shadow: 2px 2px 3px rgb(136 0 136 / 50%); */
    /* background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
     */
     box-sizing: border-box;
    background-color: rgba(255, 250, 160, 0.963);
    /* background-size: 300%; */
    color: #1e2351;
    /* border: none; */
    /* background-position: left center; */
    /* transition: background .3s ease; */

}


.tech-labels-list{
   text-align: center;
   margin-top: 10px;

}