@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');



.project-page {
  padding:1.5vh;
  margin-top: 10vh; 
}

.project-title {
  display: inline-flex; 
  align-items: center; 
  gap: 0.5rem; 
}

.banner {
  width: 100%;
  height: 33vh; /* One third of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; /* Optional: Adds a background color to the banner */
  overflow: hidden; /* Ensures the image stays within the banner */
}

.banner-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.content-section {
  max-width: 80%;
  margin: 0 auto; 

}

.content-section img {
  max-width: 100%;
  /* margin: 10px 0; */
}

.intro{
  display: flex;
}

.project-container{
  max-width: 90%;
  background-color: white;
  margin: 0 auto; 

}

.badge-container {
  display: flex;
  gap: .5vw;
  flex-wrap: wrap; 
  margin: 1vh 0; 
}

.badge {
  background-color: #f0f0f0; 
  color: #333;
  border-radius: 12px; 
  padding: .45vw .9vh; 
  font-size: 1.5vh;
  font-weight: bold;
  display: inline-block; 
}

/*--------------*/


.link-style {
  text-decoration: none; 
  color: inherit;     
 

}

.link-style:hover {
  color: gold;      
}


/*------Fades in --------*/
.content-item {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.content-item.fade-in {
  opacity: 1;
}