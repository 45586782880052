@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
.boba-buttons{
    /* display: block; */
    padding: 0.7em 1em;
    outline: none;
    border: 0;
    color: black;
    letter-spacing: 0.1em;
    /* font-family: 'rubik'; */
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: bold;
    background-color: #ffdada !important; 
    /* cursor: pointer; */
    /* z-index: 10; */
    margin-right: 20px;

}

 /*cube button by gagan-gv*/
.cube {
    position: relative;
    transition: all 0.5s;
}

.cube .bg-top {
    position: absolute;
    height: 10px;
    background: #DE6FA1;
    bottom: 100%;
    left: 3px;
    right: -5px;
    transform: skew(-45deg, 0);
    margin: 0;
    transition: all 0.4s;
}

.cube .bg-top .bg-inner {
    bottom: 0;
}

.cube .bg {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background: #DE6FA1;
    transition: all 0.4s;
}

.cube .bg-right {
    position: absolute;
    background: #DE6FA1;
    top: -5px;
    z-index: 0;
    bottom: 5px;
    width: 11px;
    left: 97%;
    transform: skew(0, -45deg);
    transition: all 0.4s;
}

.cube .bg-right .bg-inner {
    left: 0;
}

.cube .bg-inner {
    background: #FFF3F9;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
}

.cube .text {
    position: relative;
    transition: all 0.4s;
}

.cube:hover .bg-inner {
    background: #d4af37;
    transition: all 0.4s;
}

.cube:hover .text {
    color: #28282d;
    transition: all 0.4s;
}

.cube:hover .bg-right,
.cube:hover .bg,
.cube:hover .bg-top {
    background: #28282d;
}

.cube:active {
    z-index: 9999;
    animation: bounce 0.1s linear;
}


.all-button{
    letter-spacing: 0.1em;
    font-family: SpaceMono;
    text-transform: none;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    align-self: flex-end;
    margin-right:40px;
    color: rgb(92, 90, 90);
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

.button-group-tech{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.tool-title{
    margin-left:30px;
}

.tech-container{

        flex: 1;

        width: 430px;
        /* margin-top: -8vh; */
        text-align: left;
    
}