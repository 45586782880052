.projects-container{

    flex-wrap: wrap;
    justify-content: center;


    height: 80vh; 

    width: 70vw;
    
    margin: 0 auto;
    
}

.project-title,
.experience-page h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem; 
  padding: 0;
  margin-left: auto;
  margin-right: auto;

}


.project-grid{
  /* padding-left: 15vw; */
  background-color: transparent;
}

.project-main-container{
    margin-top: 30vh;
}


.project-card {
  
    overflow: hidden;
   
    cursor: pointer;
    position: relative;
    
    border-radius: 5%;
    /* border-top: 10rem; */
    width: 100%;
    border: 0;

  
    /* max-height: 1500px; */
    /* max-width: 750px;  */
    aspect-ratio: 1.3 / 1;
    margin: 0 auto;

  }
  
  .project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
  }
  
  .project-card:hover .project-image {
    transform: scale(1.05);
  }
  
  .project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(-100%);
  }

  .project-overlay h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5vw;
  }
  /**/
/* The overlay that appears when the card is hovered */
.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  transition: opacity 0.3s ease-in-out;
}

/* On hover, the overlay becomes visible */
.project-card:hover .project-overlay {
  opacity: 1;
}

.project-category {
  font-size: 0.9rem;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.project-overlay-title {
  font-size: 1.8rem;
  font-weight: bold;
  /* margin-bottom: 0.5rem; */


  /* font-size: 2.5rem; */
  margin-bottom: .5rem; 
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.project-description {
  font-size: 1rem;
  color: #eee;
  line-height: 1.5;
  margin-top: 0;
  max-width: 80%;
}

  /**/
  
  .project-card:hover .project-overlay {
    opacity: 1;
    transform: translateY(0);
  }
  

  .filter-container{
    position: sticky;
    top: 0;
    z-index: 10;
  }

  /*MOBILE*/
  @media (max-width: 767px) {
    .project-title {
      text-align: center; 
      font-size: 1.5em; 
      margin-bottom: 5vh;
    }
    
    .projects-container {
      display: block; 
      width: 100%;
      margin: 0; 
      overflow-y: auto; 
      
    }
  
    .project-grid {
      padding-left: 0;
    }
  
    .project-card {
      max-width: 80%; 
      margin: 0 auto 20px; 
      padding-bottom: 75%; 
    }
  }


/*  delete later -testing for projects page */
.projects-container {
  flex: 1; 
  overflow-y: auto;
  max-height: 80vh; 
  padding: 0 1rem; 
  padding-bottom: 10rem;
  
}

.projects-container::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}


.project-grid {
  display: flex;
  flex-wrap: wrap;

}
.project-grid > * {
  margin-bottom: -8rem; /* Adjusts the bottom gap between rows */
}

.category-title {
  font-size: 3vh;
  
  padding: 0.5rem;
  color: #333;
}

.project-card-wrapper {
  width: calc(45% - 1rem); 
  box-sizing: border-box;
  margin-top: 5vh;
}

.section-divider{
  border-top: 1px solid #9f9f9f;
  margin-top: -3px;
}


/*TESTING DELETE LATER*/
/* Sticky Title */
.sticky-title {
  position: sticky;
  top: 0; /* Adjust according to your layout */
  z-index: 10;
  padding-bottom: 1rem;
}

/* Scrollable Projects Container */
.scrollable-projects {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem 0;
}

.scrollable-projects::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}

/* Project Card Styles */
.project-card-wrapper {
  width: calc(40% - 1rem); /* You can adjust this */
  box-sizing: border-box;
  margin-top: 5vh;
  
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4rem;
  gap: 8rem;
  justify-content: center;
}

/* Adjustments for mobile */
@media (max-width: 767px) {
  .sticky-title {
    text-align: center;
    font-size: 1.5rem;
  }

  .scrollable-projects {
    max-height: 50vh;
  }

  .project-card-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .project-grid {
    flex-direction: column;
    align-items: center;
  }
}
